<template>
    <div v-loading="loading"> 
        <div style="display: flex;align-items: center;justify-content: center;margin-top: 40px;font-size: 17px;">
            <div>请上传合同:</div>
            <div v-if="fileO.length==0">
                <div style="margin-left: 20px;"><el-upload class="upload-demo" action="#" multiple :limit="1"
                        :http-request="addinvoiceUrl" :on-change="handleChange" :before-upload="beforeAvatarUpload" accept=".pdf,.png,.jpg"
                        :show-file-list="false">
                        <div style="color: cornflowerblue;">点击上传线下合同</div>
                    </el-upload></div>
            </div>
            <div v-else>
                <div style="display: flex;align-items: center;" v-for="(item,index) in fileO" :key="index">
                    <div style="margin-left: 10px;">{{item.originalName}} </div>
                    <div style="margin-left: 10px;color: cornflowerblue; cursor:pointer" @click="isaccount=true">预览
                    </div>
                    <div style="margin-left: 10px;">
                        <el-upload class="upload-demo" action="#" multiple :limit="1" :http-request="addinvoiceUrl" accept=".pdf,.png,.jpg"
                            :on-change="handleChanges" :before-upload="beforeAvatarUpload" :show-file-list="false">
                            <div style="color: cornflowerblue;">重新上传合同信息</div>
                        </el-upload>
                    </div>
                </div>
            </div>
        </div>
        <div style="display: flex;justify-content: center;margin-top: 80px;">
            <el-button @click="PreviousStep">上一步</el-button>
            <el-button type="primary" @click="nextStep">下一步</el-button>
        </div>

        <el-dialog title="预览" :visible.sync="isaccount" center append-to-body>
            <!-- <div class="">
                <div v-for="(item, index) in fileO" :key="index" style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: 20px;
                ">
                    <el-button @click="examinecontract(item.storageName)"
                        v-if="item.storageName&&item.storageName.slice(-4) == '.pdf' || item.storageName&& item.storageName.slice(-4) == '.doc'|| item.storageName&&item.storageName.slice(-5) == '.docx'||item.storageName&&item.storageName.slice(-4) == '.PDF'">点击查看文件</br></br></br>{{
                        item.originalName }}
                    </el-button>
                </div>
            </div> -->
            <div class="">
        <div v-for="(item, index) in fileO" :key="index" style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
          ">
          <el-button @click="examinecontract(item.storageName)"
                v-if="item.storageName.slice(-4) == '.pdf' || item.storageName.slice(-4) == '.PDF'">
                点击查看PDF文件</br></br></br>{{ item.originalName }}</el-button>
<el-image v-else :src="baseUrl + 'third/third/ossGetFile/' + item.storageName" style="height:100px"
                :preview-src-list="[baseUrl + 'third/third/ossGetFile/' + item.storageName]"></el-image>
              
        </div>
      </div>
        </el-dialog>
    </div>
</template>

<script>
    import { ossUploadFiletwo } from "@/api/public";
    const { baseUrl } = require("@/config");
    import { updateInviteProgress, getInviteProgress } from "@/api/foundation";
    export default {
        data() {
            return {
                loading:false,
                baseUrl: baseUrl,
                isaccount: false,
                fileId: [],
                fileO: [],
                ruleForm: {}
            };
        },
        created() {
            this.getInviteProgress()
        },
        methods: {
            examinecontract(item) {
                let url = this.baseUrl + "third/third/ossGetFile/" + item;
                console.log(url);
                window.open(url);
            },
            PreviousStep() {
                this.$emit('PreviousStep', 0);
            },
            getInviteProgress() {
                getInviteProgress().then(res => {
                    this.ruleForm = res.data
                    if (res.data.contractInfoList) {
                        this.fileO = res.data.contractInfoList
                        // this.fileId=res.data.contractInfoList
                        let list = res.data.contractInfoList
                        let newlist = []
                        for(let i=0;i<list.length;i++){
                            newlist.push(list[i].fileId)
                        }
                        this.fileId=newlist
                    }
                    console.log(this.fileO)
                })
            },
            beforeAvatarUpload(file) {
                // let isJPG;
                // if (file.type === "application/pdf") {
                //     isJPG = true;
                // } else {
                //     isJPG = false;
                //     this.$message.error("请上传pdf文件!");
                // }
                // if (!isJPG) {
                //     return false;
                // }
            },
            addinvoiceUrl(file) {

            },
            nextStep() {
                console.log(this.fileId)
                if (this.fileId.length>0) {
                    this.ruleForm.fileIds = this.fileId
                    updateInviteProgress(this.ruleForm).then(res => {
                        if (res.code == 0) {
                            this.$emit('change', res);
                        }
                    })
                } else {
                    this.$message({
                        message: '请上传合同',
                        type: 'warning'
                    });
                }

            },
            handleChange(file) {
                console.log(file)
                this.loading=true

                let otherFiles = file.raw;

                let isJPG;
                if (otherFiles.type === "application/pdf"||otherFiles.type === "image/png"||otherFiles.type === "image/jpg") {
                    isJPG = true;
                } else {
                    isJPG = false;
                    this.loading=false
                    this.$message.error("请上传pdf/jpg/png文件!");
                }
                if (!isJPG) {
                    return false;
                }


                var formData = new FormData();
                // 文件对象
                formData.append("file", otherFiles);
                ossUploadFiletwo(formData).then((res) => {
                    if (res.code == 0) {
                        this.loading=false
                        this.$message({
                            message: "上传成功",
                            type: "success",
                        });
                        this.fileId.push(res.data.fileId)
                        this.fileO.push(res.data)
                    }else{
                        this.loading=false
                    }
                });
            },
            handleChanges(file) {
                this.loading=true
                this.fileId = []
                this.fileO = []
                let otherFiles = file.raw;
                var formData = new FormData();
                // 文件对象
                formData.append("file", otherFiles);
                ossUploadFiletwo(formData).then((res) => {
                    if (res.code == 0) {
                        this.loading=false
                        this.$message({
                            message: "上传成功",
                            type: "success",
                        });
                        this.fileId.push(res.data.fileId)
                        this.fileO.push(res.data)
                        console.log(this.fileId)
                    }else{
                        this.loading=false
                    }
                });
            }
        },
    };
</script>

<style lang="scss" scoped>

</style>