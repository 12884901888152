<template>
    <div>
        <div style="display: flex;justify-content: center;align-items: center;">
            <div style="font-size: 16px;">*邀约交易的企业名称</div>
            <div style="margin-left: 20px;">
                <el-autocomplete v-model="name" clearable :fetch-suggestions="querySearchAsync" @select="handleSelects"
                    placeholder="请输入企业名称">
                </el-autocomplete>
            </div>
        </div>
        <div style="margin-top: 20px;width: 400px;margin: 0 auto;">
            <div v-if="list.length>0">
                <div v-for="(item,index) in list" :key="index"
                    style="display: flex;margin-top: 10px;border: 1px solid #ccc;">
                    <div style="text-align: center;width: 300px;border-right: 1px solid #ccc;padding: 10px;">
                        {{item.userName}} </div>
                    <div style="padding: 10px;">{{item.telephone}}</div>

                </div>
            </div>
        </div>



        <div style="display: flex;justify-content: center;margin-top: 20px;">
            <el-button @click="PreviousStep">上一步</el-button>
            <el-button type="primary" @click="nextStep">确认邀约</el-button>
        </div>
    </div>
</template>

<script>
    import { userList,getAllEnterprise } from "@/api/Medium.js";
    import { initiateInvite, getInviteProgress } from "@/api/foundation";
    export default {
        data() {
            return {
                state: '',
                form: {},
                name: '',
                list: [],
                ruleForm: {}
            };
        },

        mounted() {
            this.getInviteProgress()
        },

        methods: {
            PreviousStep(){
                this.$emit('PreviousStep', 0);
            },
            nextStep() {
                 let newlist = []
                 for(let i=0;i<this.list.length;i++){
                    newlist.push(this.list[i].userId)
                 }
                this.ruleForm.enterpriseIds =newlist
                // let fileIds=[]
                // let contractInfoList=this.ruleForm.contractInfoList
                // for(let i=0;i<contractInfoList.length;i++){
                //     fileIds.push(contractInfoList[i].fileId)
                // }
                // this.ruleForm.fileIds = fileIds
                // console.log(this.ruleForm)
                // return
                initiateInvite(this.ruleForm).then(res => {
                    if (res.code == 0) {
                        this.$message({
                            message: '邀约成功',
                            type: 'success'
                        });
                        this.$emit('change', res);
                    }else{
                        this.$message.error(res.message);
                    }
                })
            },
            getInviteProgress() {
                getInviteProgress().then(res => {
                    this.ruleForm = res.data
                    console.log(res.data)
                })
            },
            handleSelects(item) {
                this.list = []
                this.list.push(item)
            },
            querySearchAsync(queryString, callback) {
                let config = {
                    keyword: this.name
                };
                var list = [];
                getAllEnterprise(config).then((res) => {
                    if (res.code == 0) {
                        for (let i of res.data) {
                            i.value = i.userName; //将想要展示的数据作为value
                        }
                        list = res.data;
                        callback(list);
                    }
                });
            },
        },
    };
</script>

<style lang="scss" scoped>

</style>